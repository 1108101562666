import i18n from '../../../locale';
import { leasing } from '../../../data/sitemap';

export default [{
  name: leasing._,
  text: i18n('Leasing'),
  url: i18n('_url:leasing')
}, {
  name: leasing.features,
  text: i18n('Features'),
  url: i18n('_url:leasing-features')
}, {
  name: leasing.price,
  text: i18n('Pricing'),
  url: i18n('_url:leasing-pricing')
}]